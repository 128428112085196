<template>
  <video-background
      :src="require('../assets/animation/animation_three.mp4')"
      :loop="false"
      :muted="true"
      ref="videobackground"
      @playing="handlePlaying"
      @load="handleLoad"
      style="height: 100vh;">
    <div class="container h-100 overflow-auto">
      <h1 class="mt-5 song-title">{{ fields.song_title ? fields.song_title : " " }}</h1>
      <button style="display: none" ref="play" type="button" @click="onplay">Play</button>
      <div class="d-flex justify-content-center">
        <ul class="song-services">
          <li v-if="fields.spotify_url">
            <a :href="fields.spotify_url">
              <img src="../assets/imgs/spotify.png" style="width: 80px"
                   @mouseout="$event.target.src = require('../assets/imgs/spotify.png')"
                   @mouseover="$event.target.src = require('../assets/imgs/hover-spotify.png')">
            </a>
          </li>
          <li v-if="fields.apple_url">
            <a :href="fields.apple_url">
              <img src="../assets/imgs/apple.png" style="width: 90px"
                   @mouseout="$event.target.src = require('../assets/imgs/apple.png')"
                   @mouseover="$event.target.src = require('../assets/imgs/hover-apple.png')">
            </a>
          </li>
          <li v-if="fields.youtube_url">
            <a :href="fields.youtube_url">
              <img src="../assets/imgs/youtube.png" style="width: 100px"
                   @mouseout="$event.target.src = require('../assets/imgs/youtube.png')"
                   @mouseover="$event.target.src = require('../assets/imgs/hover-youtube.png')">
            </a>
          </li>
          <li v-if="fields.amazon_url">
            <a :href="fields.amazon_url">
              <img src="../assets/imgs/amazon.png" style="width: 100px"
                   @mouseout="$event.target.src = require('../assets/imgs/amazon.png')"
                   @mouseover="$event.target.src = require('../assets/imgs/hover-amazon.png')">
            </a>
          </li>
        </ul>
      </div>

    </div>
  </video-background>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Song",
  computed: {
    ...mapState([
      'fields',
    ]),
  },
  methods: {
    onplay() {
      this.$refs.videobackground.player.play()
    },
    handleLoad() {
      this.$refs.play.touchstart();
    },
    handlePlaying() {
      let that = this;
      setTimeout(() => {
        const videoElement = document.querySelector('video');
        if (!videoElement.playing) {
          that.$refs.videobackground.poster = require('../assets/imgs/bg3.png');
          that.$refs.videobackground.player.hide()
        }
      }, 2000)
    },
  }
}
</script>

<style scoped lang="scss">
.song-title {
  font-family: "NeonClubMusic";
  font-size: 20px;
  color: #fff;
  text-align: center;
}
.song-services {
  list-style: none;
  padding-left: 0;
  margin-top: 40px;
  li {
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
  }
  li:last-child {
    margin-bottom: 0;
  }
}
</style>
